import { useEffect, useRef } from 'react'
import { formatDistance, formatRFC7231 } from 'date-fns'

const { feature } = window.countryCoder

export const getCountryProperties = code => {
  const { properties } = feature(code)
  const { nameEn, emojiFlag } = properties
  return {
    code,
    nameEn,
    emojiFlag
  }
}

const ft8Frequencies = [
  1.84, 1.842, 3.573, 5.357, 7.056, 7.071, 7.074, 7.078, 10.13, 10.132, 10.133,
  10.136, 14.071, 14.074, 14.078, 14.09, 18.1, 18.104, 21.074, 21.078, 21.091,
  24.915, 28.074, 28.078, 50.31, 50.313, 50.328, 50.323, 70.1, 144.174, 222.065,
  432.065
].map(mhz => mhz * 1000000)

const getClosest = (target, freq) => {
  const diffs = target.map(f => freq - f).filter(f => f > 0)
  const sorted = diffs.sort((a, b) => a - b)
  return sorted[0]
}

export const displayFrequency = (hz, ft8, fixed) => {
  const mhz = hz / 1000000
  const ghz = mhz / 1000
  const khz = hz / 1000

  const freqMsg = fixed
    ? ghz >= 1
      ? `${ghz.toFixed(2)}GHz`
      : mhz >= 1
      ? `${mhz.toFixed(2)}MHz`
      : `${khz.toFixed(2)}kHz`
    : ghz >= 1
    ? `${ghz}GHz`
    : mhz >= 1
    ? `${mhz}MHz`
    : `${khz}kHz`
  const ft8Msg = ft8 ? ` (${getClosest(ft8Frequencies, hz)}Hz FT8 offset)` : ''
  return `${freqMsg}${ft8Msg}`
}

export const renderTooltip = (object, html, sota, inline) => {
  if (object) {
    const {
      from,
      to,
      frequency: frequencyHz,
      mode,
      band,
      timestamp,
      sotaAlert,
      report,
      distance: distancePrecise
    } = object
    const distance = Math.round(distancePrecise)
    const frequency = displayFrequency(frequencyHz, mode === 'FT8')
    const prettyDate = formatRFC7231(new Date(timestamp * 1000))
    const dateAgo = formatDistance(new Date(timestamp * 1000), new Date(), {
      addSuffix: true
    })
    const fromCallsign = from.callsign.replace('.', '/')
    const toCallsign = to.callsign.replace('.', '/')

    return html ? (
      {
        html: `<h2>${fromCallsign} spotted by ${toCallsign} <span style="font-family: 'Noto Color Emoji'">${
          from.countryData.emojiFlag
        }${
          to.countryData.emojiFlag
        }</span></h2><div>${prettyDate} (${dateAgo})</div><div>SNR ${report}</div><div>${frequency} ${band} ${mode}</div><div>${distance}km</div>${
          sotaAlert
            ? `<div><strong>May be activating summit <a href="https://summits.sota.org.uk/summit/${sotaAlert.associationCode}/${sotaAlert.summitCode}">${sotaAlert.associationCode}/${sotaAlert.summitCode}</a></strong></div>`
            : ''
        }`
      }
    ) : sota ? (
      <>
        <strong>{fromCallsign}</strong> spotted by <strong>{toCallsign}</strong>{' '}
        - {frequency} {band} {mode} ({dateAgo}) - May be activating summit{' '}
        <a
          href={`https://summits.sota.org.uk/summit/${sotaAlert.associationCode}/${sotaAlert.summitCode}`}
        >
          {sotaAlert.associationCode}/{sotaAlert.summitCode}
        </a>
        !
      </>
    ) : inline ? (
      <>
        <strong>{fromCallsign}</strong> spotted by <strong>{toCallsign}</strong>{' '}
        - {frequency} {band} {mode} ({dateAgo})
      </>
    ) : (
      <>
        <h5>
          {fromCallsign} spotted by {toCallsign}{' '}
          <span style={{ fontFamily: 'Noto Color Emoji' }}>
            {from.countryData.emojiFlag}
            {to.countryData.emojiFlag}
          </span>
        </h5>
        <div>
          {prettyDate} ({dateAgo})
        </div>
        <div>
          {frequency} {band} {mode} (SNR {report})
        </div>
        <div>{distance}km</div>
      </>
    )
  }
}

export function useInterval (callback, delay) {
  const intervalRef = useRef(null)
  const savedCallback = useRef(callback)
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])
  useEffect(() => {
    const tick = () => savedCallback.current()
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(tick, delay)
      return () => window.clearInterval(intervalRef.current)
    }
  }, [delay])
  return intervalRef
}
