import countries from './countries.json'

const modesPopular = [
  'FT8',
  'FT4',
  'WSPR',
  'CW',
  'PSK31',
  'RTTY',
  'JS8',
  '-FT8'
]

const otherModes = [
  'SSTV',
  'SSB',
  'FM',
  'VARAC',
  'MSK144',
  'OPERA',
  'FST4W',
  'JT65',
  'JT',
  'PSK',
  'JT65B',
  'PI4',
  'OLIVIA 8',
  'Q65',
  'OLIVIA 4',
  'Q65B',
  'PSK32',
  'JT9',
  'ROS',
  'THOR16',
  'CONTESTI',
  'THOR',
  'PSK63',
  'FREEDV',
  'OLIVIA 1',
  'FSK441',
  'THOR11',
  'THOR4',
  'HELL',
  'OLIVIA',
  'Q65A',
  'DOMINO',
  'T10',
  'OLIVIA 3',
  'FST4',
  'MFSK16',
  'JTMS',
  'SIM31',
  'MFSK32',
  'PSK125',
  'JT4',
  'ECHO',
  'RTTYM',
  'PSK250',
  'OLIVIA-1',
  'THOR22',
  'THOR8',
  'MFSK4',
  'MFSK128',
  'FSKH105',
  'OLIVIA-8',
  'MT63-2KL',
  'FREQCAL',
  'THRB',
  'OLIVIA-3',
  'PSK45',
  'RTTY-45',
  'THOR5'
]

const bands = [
  'vlf',
  '4000m',
  '2200m',
  '600m',
  '160m',
  '80m',
  '60m',
  '40m',
  '30m',
  '20m',
  '17m',
  '15m',
  '12m',
  '11m',
  '10m',
  '8m',
  '6m',
  '5m',
  '4m',
  '2m',
  '1.25m',
  '70cm',
  '33cm',
  '23cm',
  '2.4Ghz',
  '3.4Ghz',
  '5.8Ghz',
  '10Ghz',
  '24Ghz',
  '47Ghz',
  '76Ghz'
]

const hexToRgb = hex => {
  const [_, r, g, b] = hex.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i)
  return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)]
}

const kellyColors = [
  '#FFB300', // Vivid Yellow
  '#803E75', // Strong Purple
  '#FF6800', // Vivid Orange
  '#A6BDD7', // Very Light Blue
  '#C10020', // Vivid Red
  '#CEA262', // Grayish Yellow
  '#817066', // Medium Gray
  '#007D34', // Vivid Green
  '#F6768E', // Strong Purplish Pink
  '#00538A', // Strong Blue
  '#FF7A5C', // Strong Yellowish Pink
  '#53377A', // Strong Violet
  '#FF8E00', // Vivid Orange Yellow
  '#B32851', // Strong Purplish Red
  '#F4C800', // Vivid Greenish Yellow
  '#7F180D', // Strong Reddish Brown
  '#93AA00', // Vivid Yellowish Green
  '#593315', // Deep Yellowish Brown
  '#F13A13', // Vivid Reddish Orange
  '#232C16' // Dark Olive Green
]

const commonBandColorsHex = {
  '600m': '#CEA262',
  '160m': '#FFB300',
  '80m': '#803E75',
  '60m': '#A6BDD7',
  '40m': '#FF6800',
  '30m': '#C10020',
  '20m': '#007D34',
  '17m': '#817066',
  '15m': '#232C16',
  '12m': '#F6768E',
  '11m': '#00538A',
  '10m': '#FF7A5C',
  '8m': '#FF8E00',
  '6m': '#53377A',
  '5m': '#B32851',
  '4m': '#F4C800',
  '2m': '#7F180D',
  '1.25m': '#93AA00',
  '70cm': '#593315',
  '33cm': '#F13A13'
}

const uncommonBandColorsHex = {
  vlf: '#38412d',
  '4000m': '#91958a',
  '2200m': '#d3d4d0',
  '23cm': '#f36142',
  '2.4Ghz': '#f57559',
  '3.4Ghz': '#f68871',
  '5.8Ghz': '#f89c89',
  '10Ghz': '#f9b0a0',
  '24Ghz': '#fac3b8',
  '47Ghz': '#fcd7cf',
  '76Ghz': '#fdebe7'
}

const bandColorsHex = { ...commonBandColorsHex, ...uncommonBandColorsHex }

const bandColors = Object.entries(bandColorsHex).reduce((acc, [k, v]) => {
  acc[k] = hexToRgb(v)
  return acc
}, {})

const bandOptions = [
  { value: '+', label: 'All bands' },
  ...bands.map(b => ({ value: b, label: b }))
]

const modeOptions = [
  {
    label: 'Popular Modes',
    options: [
      { value: '+', label: 'All modes' },
      ...modesPopular.map(m => ({ value: m, label: m }))
    ]
  },
  {
    label: 'Other Modes',
    options: otherModes.map(m => ({ value: m, label: m }))
  }
]

const countryOptions = Object.entries(countries).map(entry => {
  const [code, name] = entry
  return {
    value: code,
    label: name
  }
})

const ttlOptions = [
  { label: 'Forever', value: Infinity },
  { label: '1h', value: 3600 },
  { label: '30m', value: 1800 },
  { label: '5m', value: 300 },
  { label: '30s', value: 30 },
  { label: 'Instant', value: 3 }
]

export {
  bandOptions,
  modeOptions,
  countryOptions,
  ttlOptions,
  bandColors,
  bandColorsHex
}
