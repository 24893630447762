import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { differenceInHours } from 'date-fns'
import { bufferTime } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { add, cull, importSotaAlerts } from './features/map/spotSlice'
import { connect, disconnect } from './features/settings/settingsSlice'
import { useInterval } from './utils'

const WS_FEED_BASE_PATH = 'wss://mqtt.pskreporter.info:1886'

const wsObservable = new Observable()

export function useSpotStream () {
  const [client, setClient] = useState(null)
  const dispatch = useDispatch()

  const filters = useSelector(state => state.settings.filters)
  const ttl = useSelector(state => state.settings.markers.ttl)
  const paused = useSelector(state => state.settings.ui.paused)
  const viceVersa = useSelector(state => state.settings.viceVersa)

  const { sender, receiver, mode, band } = filters
  const senderCall = sender.callsign || '+'
  const receiverCall = receiver.callsign || '+'
  const senderLocator = sender.locator || '+'
  const receiverLocator = receiver.locator || '+'
  const senderCountry = sender.country || '+'
  const receiverCountry = receiver.country || '+'

  const currentMode = mode || '+'
  const currentBand = band || '+'

  const WS_FEED_TOPIC =
    `pskr/filter/v2/${currentBand}/${currentMode}/${senderCall}/${receiverCall}` +
    `/${senderLocator}/${receiverLocator}/${senderCountry}/${receiverCountry}`

  const WS_FEED_TOPIC_VICE_VERSA =
    `pskr/filter/v2/${currentBand}/${currentMode}/${receiverCall}/${senderCall}` +
    `/${receiverLocator}/${senderLocator}/${receiverCountry}/${senderCountry}`

  useEffect(() => {
    fetch('https://api2.sota.org.uk/api/alerts')
      .then(response => response.json())
      .then(data => {
        const alerts = data.filter(
          alert =>
            Math.abs(
              differenceInHours(new Date(alert.dateActivated), new Date())
            ) <= 4
        )
        dispatch(importSotaAlerts(alerts))
      })
  }, [])

  useEffect(() => {
    const spotObservable = new Observable(observer => {
      const client = window.mqtt.connect(`${WS_FEED_BASE_PATH}`)
      client.on('connect', function () {
        client.on('message', (topic, message) => {
          const parsed = JSON.parse(message.toString())
          observer.next({ message: parsed, id: uuid(), topic })
        })
        setClient(client)
        dispatch(connect())
      })
      return () => {
        client.end()
      }
    })
      .pipe(bufferTime(200))
      .subscribe(spotList => {
        if (spotList.length > 0) {
          dispatch(add(spotList))
        }
      })

    return () => {
      spotObservable.unsubscribe()
      dispatch(disconnect())
    }
  }, [])

  useEffect(() => {
    if (!client) return
    if (
      !(
        sender.callsign ||
        sender.locator ||
        receiver.callsign ||
        receiver.locator
      )
    ) {
      return
    }

    if (paused) {
      client.unsubscribe(WS_FEED_TOPIC)
      client.unsubscribe(WS_FEED_TOPIC_VICE_VERSA)
      return
    }

    client.subscribe(WS_FEED_TOPIC, err => {
      if (!err) {
        console.log(WS_FEED_TOPIC)
      } else {
        console.error(err)
      }
    })

    if (viceVersa) {
      client.subscribe(WS_FEED_TOPIC_VICE_VERSA, err => {
        if (!err) {
          console.log(WS_FEED_TOPIC_VICE_VERSA)
        } else {
          console.error(err)
        }
      })
    }

    return () => {
      if (client) {
        client.unsubscribe(WS_FEED_TOPIC)
        client.unsubscribe(WS_FEED_TOPIC_VICE_VERSA)
      }
    }
  }, [filters, currentBand, currentMode, client, paused, viceVersa])

  useInterval(() => {
    if (ttl === Infinity) return
    const now = `${new Date()}`
    dispatch(cull({ now, ttl }))
  }, 1000)
}
