import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatDistance, formatRFC7231 } from 'date-fns'
import { useSpring, animated } from '@react-spring/web'
import CountUp from 'react-countup'
import {
  Card,
  Space,
  Statistic,
  Input,
  Button,
  Select,
  Checkbox,
  Drawer,
  Slider,
  Switch,
  Form,
  Tooltip
} from 'antd'
import {
  SyncOutlined,
  CheckCircleTwoTone,
  UserOutlined,
  TableOutlined,
  RightOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import {
  bandOptions,
  modeOptions,
  countryOptions,
  ttlOptions
} from '../../options'
import {
  clearReceiver,
  clearSender,
  filterByBand,
  filterByMode,
  filterReceiver,
  filterSender,
  showAbout,
  showReceiverMarkers,
  showTransmitterMarkers,
  ttl,
  darkMode,
  showSettings,
  markerSizeByReport,
  showArcs,
  togglePause,
  dropSenderLocator,
  dropReceiverLocator,
  viceVersa,
  showBandColors
} from './settingsSlice'
import { selectSpotCount, clearAll } from '../map/spotSlice'
import { FeaturedSpots } from '../featured-spots/FeaturedSpots'

export function SettingsPane (props) {
  const { messageApi, notificationApi } = props
  const dispatch = useDispatch()

  const {
    darkMode: darkModeValue,
    settingsOpen,
    loading,
    paused
  } = useSelector(state => state.settings.ui)

  const viceVersaChecked = useSelector(state => state.settings.viceVersa)

  const {
    showTransmitterMarkers: showTransmitterMarkersChecked,
    showReceiverMarkers: showReceiverMarkersChecked,
    markerSizeByReport: markerSizeByReportChecked,
    showArcs: showArcsChecked,
    showBandColors: showBandColorsChecked,
    ttl: ttlValue
  } = useSelector(state => state.settings.markers)

  const spotCount = useSelector(selectSpotCount)
  const visibleCount = useSelector(state =>
    Math.max(...[0, ...Object.values(state.spots.markerCounts)])
  )
  const filters = useSelector(state => state.settings.filters)

  /*const interpolator = useSpring({
    // spotsPerSec: spotsPerSec.next,
    spotCount: spotCount.next,
    from: {
      //  spotsPerSec: spotsPerSec.prev,
      spotCount: spotCount.prev
    }
  })*/

  const [senderActiveTab, setSenderActiveTab] = useState('locator')
  const [receiverActiveTab, setReceiverActiveTab] = useState('all')

  const tabListBase = [
    {
      key: 'callsign',
      tab: 'Callsign'
    },
    {
      key: 'locator',
      tab: 'Grid'
    },
    {
      key: 'country',
      tab: 'Country'
    },
    {
      key: 'all',
      tab: 'Any'
    }
  ]

  const requiredTabs = ['callsign', 'locator']
  // ensure that one of receiver or sender is always callsign or locator
  const tabListReceiver = tabListBase.map(tab =>
    requiredTabs.includes(senderActiveTab)
      ? tab
      : requiredTabs.includes(tab.key)
      ? tab
      : { ...tab, disabled: true }
  )

  const tabListSender = tabListBase.map(tab =>
    requiredTabs.includes(receiverActiveTab)
      ? tab
      : requiredTabs.includes(tab.key)
      ? tab
      : { ...tab, disabled: true }
  )

  const senderLocatorInputRef = useRef()
  const receiverLocatorInputRef = useRef()

  useEffect(() => {
    if (filters.receiver.locator) {
      receiverLocatorInputRef.current.input.value = filters.receiver.locator
    }
  }, [filters.receiver.locator, receiverLocatorInputRef])

  useEffect(() => {
    if (filters.sender.locator) {
      senderLocatorInputRef.current.input.value = filters.sender.locator
    }
  }, [filters.sender.locator, senderLocatorInputRef])

  const sender = {
    callsign: (
      <form
        onSubmit={e => {
          e.preventDefault()
          const callsign = e.target[0].value.toUpperCase().replace('/', '.')
          dispatch(filterSender({ callsign }))
        }}
      >
        <Input.Group compact style={{ display: 'flex' }}>
          <Input placeholder='Callsign' prefix={<UserOutlined />} />
          <Button type='primary' htmlType='submit'>
            Filter
          </Button>
        </Input.Group>
      </form>
    ),
    locator: (
      <form
        onSubmit={e => {
          e.preventDefault()
          const locator = e.target[0].value.toUpperCase().substr(0, 4)
          const valid = /^[A-R]{2}[0-9]{2}$/.test(locator)
          if (valid) {
            dispatch(filterSender({ locator }))
          } else {
            messageApi.error('Invalid locator')
            senderLocatorInputRef.current.input.value = ''
          }
        }}
      >
        <Input.Group compact style={{ display: 'flex' }}>
          <Input
            ref={senderLocatorInputRef}
            placeholder='Grid locator'
            suffix={
              <Tooltip label='Pick from the map'>
                <TableOutlined
                  onClick={() => {
                    dispatch(
                      dropSenderLocator({
                        mobile: window.innerWidth < 768
                      })
                    )
                  }}
                />
              </Tooltip>
            }
          />
          <Button type='primary' htmlType='submit'>
            Filter
          </Button>
        </Input.Group>
      </form>
    ),
    country: (
      <Select
        options={countryOptions}
        showSearch
        style={{ width: '100%' }}
        allowClear={true}
        onClear={() => {
          dispatch(filterSender({ country: null }))
        }}
        onChange={value => {
          dispatch(filterSender({ country: value }))
        }}
        filterOption={(input, option) => {
          const { label } = option
          return (label || '').toLowerCase().includes(input.toLowerCase())
        }}
      />
    )
  }

  const receiver = {
    callsign: (
      <form
        onSubmit={e => {
          e.preventDefault()
          const callsign = e.target[0].value.toUpperCase().replace('/', '.')
          dispatch(filterReceiver({ callsign }))
        }}
      >
        <Input.Group compact style={{ display: 'flex' }}>
          <Input placeholder='Callsign' prefix={<UserOutlined />} />
          <Button type='primary' htmlType='submit'>
            Filter
          </Button>
        </Input.Group>
      </form>
    ),
    locator: (
      <form
        onSubmit={e => {
          e.preventDefault()
          const locator = e.target[0].value.toUpperCase().substr(0, 4)
          const valid = /^[A-R]{2}[0-9]{2}$/.test(locator)
          if (valid) {
            dispatch(filterReceiver({ locator }))
          } else {
            messageApi.error('Invalid locator')
            receiverLocatorInputRef.current.input.value = ''
          }
        }}
      >
        <Input.Group compact style={{ display: 'flex' }}>
          <Input
            ref={receiverLocatorInputRef}
            placeholder='Grid locator'
            suffix={
              <Tooltip label='Pick from the map'>
                <TableOutlined
                  onClick={() => {
                    dispatch(
                      dropReceiverLocator({
                        mobile: window.innerWidth < 768
                      })
                    )
                  }}
                />
              </Tooltip>
            }
          />

          <Button type='primary' htmlType='submit'>
            Filter
          </Button>
        </Input.Group>
      </form>
    ),
    country: (
      <Select
        options={countryOptions}
        showSearch
        style={{ width: '100%' }}
        allowClear={true}
        onClear={() => {
          dispatch(filterReceiver({ country: null }))
        }}
        onChange={value => {
          dispatch(filterReceiver({ country: value }))
        }}
        filterOption={(input, option) => {
          const { label } = option
          try {
            return (label || '').toLowerCase().includes(input.toLowerCase())
          } catch (e) {}
        }}
      />
    )
  }

  return (
    <Drawer
      placement='right'
      open={settingsOpen}
      {...(window.innerWidth < 768 && { width: '100%' })}
      closeIcon={<RightOutlined />}
      closable={true}
      onClose={() => {
        dispatch(showSettings(false))
      }}
      mask={false}
      title={
        <Space
          direction='horizontal'
          style={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'baseline'
          }}
        >
          <h3 style={{ margin: '0px' }}>ft8.live</h3>
          <Button
            type='link'
            style={{ margin: '0px' }}
            onClick={() => {
              dispatch(showAbout(true))
            }}
          >
            about
          </Button>
          <Form.Item
            label='Dark mode'
            colon={false}
            style={{ margin: '0px', alignItems: 'baseline' }}
          >
            <Switch
              checked={darkModeValue}
              size='small'
              onChange={checked => {
                dispatch(darkMode(checked))
              }}
            />
          </Form.Item>
        </Space>
      }
      footer={<FeaturedSpots notificationApi={notificationApi} />}
      footerStyle={{
        maxHeight: '50vh',
        overflowY: 'auto'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          height: '100%',
          padding: '12px'
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '8px'
          }}
        >
          <Card bordered={false} size='small'>
            <Space direction='vertical' style={{ width: '100%' }}>
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <div style={{ flex: '1' }}>
                  <Space direction='vertical'>
                    {loading ? (
                      <span>
                        <SyncOutlined spin /> Connecting...
                      </span>
                    ) : (
                      <span>
                        <CheckCircleTwoTone twoToneColor='#52c41a' /> Connected
                      </span>
                    )}
                    <Button
                      size='small'
                      onClick={() => dispatch(togglePause())}
                      icon={paused ? <PlayCircleOutlined /> : <PauseOutlined />}
                    >
                      {paused ? 'Resume stream' : 'Pause stream'}
                    </Button>
                  </Space>
                </div>

                <Statistic
                  style={{ marginRight: '12px' }}
                  title='Spots'
                  loading={loading}
                  //formatter={value => /*<CountUp end={value} separator=',' />*/}
                  value={spotCount}
                />
              </div>

              <Space
                direction='horizontal'
                style={{ width: '100%', alignItems: 'baseline', flex: '1' }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '8px',
                    alignItems: 'baseline'
                  }}
                >
                  <label style={{ marginRight: '8px' }}>Spot lifespan</label>
                  <Select
                    defaultValue={Infinity}
                    options={ttlOptions}
                    style={{ flex: 1 }}
                    onChange={value => {
                      dispatch(ttl(value))
                    }}
                    value={ttlValue}
                  />
                </div>

                <Button
                  type='default'
                  onClick={() => dispatch(clearAll())}
                  icon={<DeleteOutlined />}
                >
                  Clear all
                </Button>
              </Space>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '8px'
                }}
              >
                <Select
                  defaultValue={'+'}
                  value={filters.mode}
                  options={modeOptions}
                  showSearch
                  style={{ width: '100%', marginRight: '8px' }}
                  allowClear={false}
                  onChange={value => {
                    dispatch(filterByMode(value))
                  }}
                />
                <Select
                  defaultValue={'+'}
                  value={filters.band}
                  options={bandOptions}
                  style={{ width: '100%' }}
                  showSearch
                  allowClear={false}
                  onChange={value => dispatch(filterByBand(value))}
                />
              </div>

              <Checkbox
                onChange={e => {
                  dispatch(viceVersa(!e.target.checked))
                }}
                checked={!viceVersaChecked}
              >
                Filter by transmitter and receiver
              </Checkbox>
            </Space>
          </Card>
        </div>

        <Card
          type='inner'
          style={{
            flex: '0',
            border: 'none',
            boxShadow: 'none',
            marginTop: '12px'
          }}
          tabProps={{
            size: 'small'
          }}
          bordered={false}
          size='small'
          tabList={tabListSender}
          defaultActiveTabKey='callsign'
          activeTabKey={senderActiveTab}
          onTabChange={key => {
            setSenderActiveTab(key)
            if (key === 'all') {
              dispatch(clearSender())
            }
          }}
          title={viceVersaChecked ? 'One station is...' : 'Transmitter is...'}
        >
          {sender[senderActiveTab]}
        </Card>

        <Card
          type='inner'
          style={{
            flex: '0',
            border: 'none',
            boxShadow: 'none',
            marginTop: '12px'
          }}
          bordered={false}
          size='small'
          tabList={tabListReceiver}
          tabProps={{
            size: 'small'
          }}
          defaultActiveTabKey='callsign'
          activeTabKey={receiverActiveTab}
          onTabChange={key => {
            setReceiverActiveTab(key)
            if (key === 'all') {
              dispatch(clearReceiver())
            }
          }}
          title={viceVersaChecked ? 'Other station is...' : 'Receiver is...'}
        >
          {receiver[receiverActiveTab]}
        </Card>

        <Card
          bordered={false}
          size='small'
          title={'Marker options'}
          style={{ marginTop: '8px' }}
        >
          <Space direction='vertical'>
            {/*<Checkbox
                onChange={e => {
                  setRetain(e.target.checked)
                }}
                checked={retain}
              >
                Retain spots when filters change
            </Checkbox>*/}
            <Checkbox
              onChange={e => {
                dispatch(showTransmitterMarkers(e.target.checked))
              }}
              checked={showTransmitterMarkersChecked}
            >
              <span style={{ color: 'red' }}>Transmitter</span> markers
            </Checkbox>
            <Checkbox
              onChange={e => {
                dispatch(showReceiverMarkers(e.target.checked))
              }}
              checked={showReceiverMarkersChecked}
            >
              <span style={{ color: 'green' }}>Receiver</span> markers
            </Checkbox>
            <Checkbox
              onChange={e => {
                dispatch(markerSizeByReport(e.target.checked))
              }}
              checked={markerSizeByReportChecked}
            >
              Size markers proportionate to report
            </Checkbox>
            <Checkbox
              onChange={e => {
                dispatch(showArcs(e.target.checked))
              }}
              checked={showArcsChecked}
            >
              Show all arcs between rx and tx
            </Checkbox>
            <Checkbox
              onChange={e => {
                dispatch(showBandColors(e.target.checked))
              }}
              checked={showBandColorsChecked}
            >
              Show band colors
            </Checkbox>
          </Space>
        </Card>
      </div>
    </Drawer>
  )
}
