/// app.js
import 'antd/dist/reset.css'
import './App.css'
import { useEffect } from 'react'
import { Tooltip, ConfigProvider, FloatButton, theme, notification, message } from 'antd'
import { MenuOutlined, CompressOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { SettingsPane } from './features/settings/SettingsPane'
import { SpotMap } from './features/map/SpotMap'
import { AboutPane } from './features/about/AboutPane'
import { useSpotStream } from './useSpotStream'
import {
  dropSenderLocator,
  filterSender,
  showSettings
} from './features/settings/settingsSlice'
import { latLngToLocator } from 'qth-locator'


function App (props) {
  const dispatch = useDispatch()
  const { darkMode, droppingReceiverLocator, droppingSenderLocator, loading } =
    useSelector(state => state.settings.ui)
  useSpotStream()
  const [notificationApi, contextHolderNotification] =
    notification.useNotification()
  const [messageApi, contextHolderMessage] = message.useMessage()

  /*useEffect(() => {
    if (firstRun) {
      dispatch(filterSender({ locator: 'DM04' }))
    }
  }, [firstRun])*/

  const settingsOpen = useSelector(state => state.settings.ui.settingsOpen)

  useEffect(() => {
    if (droppingSenderLocator || droppingReceiverLocator) {
      messageApi.info('Now tap on the map to pick a grid location')
    }
  }, [droppingSenderLocator, droppingReceiverLocator])

  useEffect(() => {
    if (!loading && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(position => {
        dispatch(
          filterSender({
            locator: latLngToLocator(
              position.coords.latitude,
              position.coords.longitude
            ).slice(0, 4)
          })
        )
      })
    }
  }, [loading])

  return (
    <ConfigProvider
      theme={{
        algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm
      }}
    >
      {contextHolderNotification}
      {contextHolderMessage}
      <SpotMap messageApi={messageApi} />
      <SettingsPane messageApi={messageApi} notificationApi={notificationApi} />
      <AboutPane />
      {!settingsOpen && (
        <FloatButton
          type='primary'
          icon={<MenuOutlined />}
          style={{
            position: 'absolute',
            top: '24px',
            right: '24px',
            zIndex: '1000'
          }}
          onClick={() => {
            dispatch(showSettings(true))
          }}
        />
      )}
      <Tooltip placement='right' title='Pick Maidenhead grid location'><FloatButton
        type='primary'
        icon={<CompressOutlined />}
        style={{
          position: 'absolute',
          top: '24px',
          left: '24px',
          zIndex: '1000'
        }}
        onClick={() => {
          dispatch(dropSenderLocator({mobile: false}))
        }}
      /></Tooltip>
    </ConfigProvider>
  )
}

export default App
