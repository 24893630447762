import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialStation = {
  callsign: null,
  locator: null,
  country: null
}

const initialState = {
  filters: {
    mode: '+',
    band: '+',
    sender: initialStation,
    receiver: initialStation
  },
  viceVersa: true,
  timeRange: [-Infinity, Infinity],
  reportRange: [-Infinity, Infinity],
  frequencyRange: [-Infinity, Infinity],
  markers: {
    showTransmitterMarkers: true,
    showReceiverMarkers: true,
    markerSizeByReport: false,
    showArcs: false,
    showBandColors: false,
    ttl: Infinity
  },
  ui: {
    loading: true,
    darkMode: false,
    settingsOpen: true,
    aboutOpen: false,
    transmitterActiveTab: 0,
    receiverActiveTab: 0,
    paused: false,
    droppingSenderLocator: false,
    droppingReceiverLocator: false
  }
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    filterByMode: (state, action) => {
      state.filters.mode = action.payload
    },
    filterByBand: (state, action) => {
      state.filters.band = action.payload
    },
    filterSender: (state, action) => {
      state.ui.droppingSenderLocator = false
      state.filters.sender = action.payload
    },
    clearSender: (state, action) => {
      state.filters.sender = initialStation
    },
    filterReceiver: (state, action) => {
      state.ui.droppingReceiverLocator = false
      state.filters.receiver = action.payload
    },
    clearReceiver: (state, action) => {
      state.filters.receiver = initialStation
    },
    viceVersa: (state, action) => {
      state.viceVersa = action.payload
    },
    showTransmitterMarkers: (state, action) => {
      state.markers.showTransmitterMarkers = action.payload
    },
    showReceiverMarkers: (state, action) => {
      state.markers.showReceiverMarkers = action.payload
    },
    showBandColors: (state, action) => {
      state.markers.showBandColors = action.payload
    },
    markerSizeByReport: (state, action) => {
      state.markers.markerSizeByReport = action.payload
    },
    showArcs: (state, action) => {
      state.markers.showArcs = action.payload
    },
    ttl: (state, action) => {
      state.markers.ttl = action.payload
      if (ttl === 3) {
        state.markers.showTransmitterMarkers = true
      }
    },
    connect: state => {
      state.ui.loading = false
    },
    disconnect: state => {
      state.ui.loading = true
    },
    showSettings: (state, action) => {
      state.ui.settingsOpen = action.payload
    },
    showAbout: (state, action) => {
      state.ui.aboutOpen = action.payload
    },
    filterTime (state, action) {
      state.timeRange = action.payload
    },
    filterReport (state, action) {
      state.reportRange = action.payload
    },
    filterFrequency(state, action) {
      state.frequencyRange = action.payload
    },
    darkMode: (state, action) => {
      state.ui.darkMode = action.payload
    },
    togglePause: state => {
      state.ui.paused = !state.ui.paused
    },
    dropReceiverLocator: (state, action) => {
      const { mobile } = action.payload
      if (mobile) state.ui.settingsOpen = false
      state.ui.droppingReceiverLocator = true
    },
    dropSenderLocator: (state, action) => {
      const { mobile } = action.payload
      if (mobile) state.ui.settingsOpen = false
      state.ui.droppingSenderLocator = true
    }
  }
})

export const {
  filterByMode,
  filterByBand,
  filterSender,
  clearSender,
  filterReceiver,
  clearReceiver,
  viceVersa,
  showTransmitterMarkers,
  showReceiverMarkers,
  markerSizeByReport,
  showArcs,
  ttl,
  connect,
  disconnect,
  showSettings,
  showAbout,
  filterTime,
  filterReport,
  darkMode,
  togglePause,
  dropReceiverLocator,
  dropSenderLocator,
  filterFrequency,
  showBandColors
} = settingsSlice.actions

export default settingsSlice.reducer
