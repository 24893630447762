import { createSelector, createSlice } from '@reduxjs/toolkit'
import { locatorToLatLng } from 'qth-locator'
import { greatCircleDistance } from 'great-circle-distance'
import { getCountryProperties } from '../../utils'
const { iso1A2Code } = window.countryCoder

const initialState = {
  spots: [],
  portableIds: [],
  sotaIds: [],
  sotaAlerts: [],
  markerCounts: {}
}

export const spotSlice = createSlice({
  name: 'spots',
  initialState,
  reducers: {
    clearAll: state => {
      state.spots = []
    },
    importSotaAlerts: (state, action) => {
      state.sotaAlerts = action.payload
    },
    reportCount: (state, action) => {
      const { layer, count } = action.payload
      state.markerCounts[layer] = count
    },
    cull: (state, action) => {
      const { now: nowStr, ttl } = action.payload
      const now = new Date(nowStr)
      state.spots = state.spots
        .map(spot =>
          now - new Date(spot.received) >= ttl * 1000
            ? { ...spot, hide: nowStr }
            : spot
        ) /* allow time for the fade out animation to finish before removing spot */
        .filter(
          ({ hide }) =>
            hide === undefined || new Date(now) - new Date(hide) < 2000
        )
    },
    add: (state, action) => {
      action.payload.forEach(data => {
        const { message, id, topic } = data
        const { rl, sl, sc, rc, sa, ra, rp, f, md, b, t } = message
        // {"sq":32927159252,"f":14076587,"md":"FT8","rp":-3,"t":1672811924,"sc":"HL3BAT","sl":"PM36QI","rc":"JJ1QWP","rl":"PM95tr","sa":137,"ra":339,"b":"20m"}

        const [rLat, rLong] = locatorToLatLng(rl)
        const [sLat, sLong] = locatorToLatLng(sl)

        const distance = greatCircleDistance({
          lat1: rLat,
          lng1: rLong,
          lat2: sLat,
          lng2: sLong
        })

        const sotaAlert = state.sotaAlerts.find(
          alert => alert.activatingCallsign === sc
        )

        const countryData = {
          sender: getCountryProperties(iso1A2Code([sLong, sLat])),
          receiver: getCountryProperties(iso1A2Code([rLong, rLat]))
        }

        const spot = {
          from: {
            coords: [sLong, sLat],
            callsign: sc,
            country: sa,
            countryData: countryData.sender
          },
          to: {
            coords: [rLong, rLat],
            callsign: rc,
            country: ra,
            countryData: countryData.receiver
          },
          received: `${new Date()}`,
          timestamp: t,
          report: rp,
          frequency: f,
          mode: md,
          band: b,
          distance,
          sotaAlert,
          id
        }

        state.spots.push(spot)
        if ( spot.from.callsign.endsWith('.P') || spot.to.callsign.endsWith('.P')) {
          state.portableIds.push(id)
        }
      })
    }
  }
})

export const { add, clearAll, cull, importSotaAlerts, reportCount } =
  spotSlice.actions

export const selectFarthestDistance = createSelector(
  [state => state.spots.spots],
  spots => {
    return spots.reduce((acc, spot) => {
      return !acc || spot.distance > acc.distance ? spot : acc
    }, null)
  }
)

export const selectOldestTimestamp = createSelector(
  [state => state.spots.spots],
  spots => {
    return spots.reduce((acc, spot) => {
      return !acc || spot.timestamp < acc ? spot.timestamp : acc
    }, null)
  }
)

export const selectNewestTimestamp = createSelector(
  [state => state.spots.spots],
  spots => {
    return spots.reduce((acc, spot) => {
      return !acc || spot.timestamp > acc ? spot.timestamp : acc
    }, null)
  }
)

export const selectHighestReport = createSelector(
  [state => state.spots.spots],
  spots => {
    return spots.reduce((acc, spot) => {
      return !acc || spot.report > acc.report ? spot : acc
    }, null)
  }
)

export const selectLowestReport = createSelector(
  [state => state.spots.spots],
  spots => {
    return spots.reduce((acc, spot) => {
      return !acc || spot.report < acc.report ? spot : acc
    }, null)
  }
)

export const selectFeaturedTxCountries = createSelector(
  [state => state.spots.spots],
  spots => {
    const countries = spots.reduce((acc, spot) => {
      const { countryData } = spot.from
      const { code } = countryData
      if (acc[code]) {
        acc[code] = acc[code] + 1
      } else {
        acc[code] = 1
      }
      return acc
    }, {})
    const sortedCountries = Object.keys(countries).sort((a, b) => {
      return countries[b] - countries[a]
    })
    const top = sortedCountries.slice(0, 5)
    const bottom = top.length < 5 ? [] : sortedCountries.slice(-5)
    return {
      top,
      bottom
    }
  }
)

export const selectFeaturedRxCountries = createSelector(
  [state => state.spots.spots],
  spots => {
    const countries = spots.reduce((acc, spot) => {
      const { countryData } = spot.to
      const { code } = countryData
      if (acc[code]) {
        acc[code] = acc[code] + 1
      } else {
        acc[code] = 1
      }
      return acc
    }, {})
    const sortedCountries = Object.keys(countries).sort((a, b) => {
      return countries[b] - countries[a]
    })
    const top = sortedCountries.slice(0, 5)
    const bottom = top.length < 5 ? [] : sortedCountries.slice(-5)
    return {
      top,
      bottom
    }
  }
)

export const selectPortableCallsigns = createSelector(
  [state => state.spots.portableIds, state => state.spots.spots],
  (portableIds, spots) => {
    let portables = spots.filter(spot => {
      portableIds.includes(spot.id)
    }).filter((spot, index, self) => {
      return (
        index ===
        self.findIndex(
          t =>
            t.from.callsign === spot.from.callsign ||
            t.to.callsign === spot.to.callsign
        )
      ) 
    })

    portables.sort((a, b) => {
      return b.timestamp - a.timestamp
    })
    return portables
  }
)

export const selectSotaActivators = createSelector(
  [state => state.spots.spots],
  spots => {
    let sotaActivators = spots.filter(spot => spot.sotaAlert)
    sotaActivators.sort((a, b) => {
      return b.timestamp - a.timestamp
    })
    return sotaActivators
  }
)

export const selectSpotCount = createSelector(
  [state => state.spots.spots],
  spots => {
    return spots.length
  }
)

export const selectLowestFrequency = createSelector(
  [state => state.spots.spots],
  spots => {
    return spots.reduce((acc, spot) => {
      return !acc || spot.frequency < acc ? spot.frequency : acc
    }, 0)
  }
)

export const selectHighestFrequency = createSelector(
  [state => state.spots.spots],
  spots => {
    return spots.reduce((acc, spot) => {
      return !acc || spot.frequency > acc ? spot.frequency : acc
    }, 0)
  }
)

export default spotSlice.reducer

/*
  const [spotsPerSec, setSpotsPerSec] = useState({ prev: 0, next: 0 })

  useInterval(() => {
    /* Calculate a rolling average of spots per second 
    const windowSize = 1000
    const slice = liveData.slice(-windowSize)
    const first = slice[0] || { received: new Date() }
    const last = slice[slice.length - 1] || { received: new Date() }
    const currentAvgRate =
      (slice.length * 60 * 60) / (last.received - first.received)

    setSpotsPerSec(({ next }) => ({ prev: next, next: currentAvgRate }))
    
    setDataCount(({ next }) => ({
        prev: next,
        next: liveData.spots.length
      }))
    }, 1000)
  
*/
