import { useDispatch, useSelector } from 'react-redux'
import { Space, Drawer, Typography, Tooltip, Card } from 'antd'
import { showAbout } from '../settings/settingsSlice'

export function AboutPane (props) {
  const dispatch = useDispatch()
  const showAboutValue = useSelector(state => state.settings.ui.aboutOpen)
  return (
    <Drawer
      title='About'
      placement='right'
      closable={true}
      onClose={() => {
        dispatch(showAbout(false))
      }}
      open={showAboutValue}
    >
      <Card>
        <Space direction='vertical' style={{ padding: '12px' }}>
          <h2>What is this?</h2>
          <Typography.Paragraph>
            ft8.live displays amateur radio{' '}
            <Tooltip
              title='A spot represents a
          radio transmission from one station being heard by another station.'
            >
              spots
            </Tooltip>{' '}
            from the realtime PSKreporter MQTT stream. ft8.live is named after
            the most popular digital mode, FT8. However, like PSK Reporter, it
            also displays spots from other modes, such as CW, WSPR and JT65.
          </Typography.Paragraph>
          <Typography.Paragraph>
            ft8.live is designed for realtime use - it does not serve any
            historical data. If you want to see historical data, you can use the
            official <a href='https://pskreporter.info'>PSK Reporter</a> map.
          </Typography.Paragraph>
          <h2>Credits</h2>
          <Typography.Paragraph>
            The UI was created by Arron ZL1AN. The spot data is from{' '}
            <a href='https://pskreporter.info'>PSK Reporter</a> by Philip
            Gladstone N1DQ, via the PSK Reporter{' '}
            <a href='http://mqtt.pskreporter.info'>MQTT stream</a> by Tom
            Stanton M0LTE.
          </Typography.Paragraph>
          <h2>SOTA chasing with ft8.live</h2>
          <Typography.Paragraph>
            ft8.live grabs SOTA alerts from the{' '}
            <a href='https://api2.sota.org.uk/docs/index.html'>SOTA API</a> to
            detect potential SOTA activators. A transmitting station is
            considered a SOTA activator if the activating callsign in the alert
            matches the TX callsign in the spot and the activation time is within
            8 hours of the time the spot was received.
          </Typography.Paragraph>
        </Space>
      </Card>
    </Drawer>
  )
}
