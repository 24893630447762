import { useDispatch, useSelector } from 'react-redux'
import { Card, Slider } from 'antd'
import { formatDistance, formatRFC7231 } from 'date-fns'
import {
  selectHighestFrequency,
  selectLowestFrequency,
  selectNewestTimestamp,
  selectOldestTimestamp
} from '../map/spotSlice'
import { filterReport, filterTime, filterFrequency } from './settingsSlice'
import { displayFrequency } from '../../utils'
import { bandColorsHex } from '../../options'

const highestFrequency = 1240000000
const lowestFrequency = 135700

const ituRegion3BandSliderMarks = Object.fromEntries(
  Object.entries({
    135700: '2200m',
    472000: '630m',
    1800000: '160m',
    3500000: '80m',
    5351500: '60m',
    7000000: '40m',
    //   10100000: '30m',
    14000000: '20m',
    //   18068000: '17m',
    21000000: '15m',
    //   24890000: '12m',
    28000000: '10m',
    50000000: '6m',
    144000000: '2m',
    430000000: '70cm',
    1240000000: '23cm'
  }).map(([frequency, band]) => [
    /* logarithmic scale */
    ((Math.log(frequency) - Math.log(lowestFrequency)) /
      (Math.log(highestFrequency) - Math.log(lowestFrequency))) *
      100,
    {
      label: <strong>{band}</strong>,
      style: {
        color: bandColorsHex[band]
      }
    }
  ])
)

export function Sliders (props) {
  const dispatch = useDispatch()
  const oldestTimestamp = useSelector(selectOldestTimestamp)
  const newestTimestamp = useSelector(selectNewestTimestamp)

  const relativePoint = n => {
    const range = newestTimestamp * 1000 - oldestTimestamp * 1000
    return Math.round((n / 100) * range + oldestTimestamp * 1000)
  }

  const sliderMarks =
    window.innerWidth < 768
      ? Object.fromEntries(
          Object.entries(ituRegion3BandSliderMarks).filter(
            ([k, v], i) => i % 2 === 0
          )
        )
      : ituRegion3BandSliderMarks

  const labelStyle = {}

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: '46px',
        left: '0px',
        zIndex: '1000'
      }}
    >
      <Card
        style={{
          width: '100%',
          opacity: '0.6'
        }}
        bodyStyle={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '8px 32px',
          paddingRight: '42px'
        }}
      >
        <Slider
          style={{ width: '100%' }}
          range={{ draggableTrack: true }}
          marks={{
            0: { label: 'Oldest', style: labelStyle },
            100: { label: 'Newest', style: labelStyle }
          }}
          disabled={!oldestTimestamp}
          tooltip={{
            formatter: n => {
              const dt = new Date(relativePoint(n))
              const prettyDate = formatRFC7231(dt)
              const dateAgo = formatDistance(dt, new Date(), {
                addSuffix: true
              })
              return `${prettyDate} (${dateAgo})`
            }
          }}
          onChange={([start, end]) => {
            dispatch(
              filterTime([
                start === 0 ? -Infinity : relativePoint(start) / 1000,
                end === 100 ? Infinity : relativePoint(end) / 1000
              ])
            )
          }}
          defaultValue={[0, 100]}
        />
        <Slider
          style={{ width: '100%' }}
          range={{ draggableTrack: true }}
          disabled={!oldestTimestamp}
          marks={{
            0: { label: '-SNR', style: labelStyle },
            100: { label: '+SNR', style: labelStyle }
          }}
          tooltip={{
            formatter: n => {
              return `${n === 0 ? '-∞' : n === 100 ? '∞' : n - 50} dB`
            }
          }}
          onChange={([start, end]) => {
            dispatch(
              filterReport([
                start === 0 ? -Infinity : start - 50,
                end === 100 ? Infinity : end - 50
              ])
            )
          }}
          defaultValue={[0, 100]}
        />
        <Slider
          style={{ width: '100%' }}
          range={{ draggableTrack: true }}
          disabled={!lowestFrequency}
          marks={sliderMarks}
          tooltip={{
            formatter: n => {
              return displayFrequency(
                n === -Infinity
                  ? lowestFrequency
                  : n === Infinity
                  ? highestFrequency /* logarithmic */
                  : Math.exp(
                      (Math.log(highestFrequency) - Math.log(lowestFrequency)) *
                        (n / 100) +
                        Math.log(lowestFrequency)
                    ),
                false,
                true
              )
            }
          }}
          onChange={([start, end]) => {
            dispatch(
              filterFrequency([
                start === 0
                  ? -Infinity
                  : Math.exp(
                      (Math.log(highestFrequency) - Math.log(lowestFrequency)) *
                        (start / 100) +
                        Math.log(lowestFrequency)
                    ),
                end === 100
                  ? Infinity
                  : Math.exp(
                      (Math.log(highestFrequency) - Math.log(lowestFrequency)) *
                        (end / 100) +
                        Math.log(lowestFrequency)
                    )
              ])
            )
          }}
          defaultValue={[0, 100]}
        />
      </Card>
    </div>
  )
}
