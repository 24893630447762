import { useEffect, useState } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import * as GeoJSONTerminator from '@webgeodatavore/geojson.terminator'
import DeckGL from '@deck.gl/react'
import { Map } from 'react-map-gl'
import {
  GreatCircleLayer,
  ScatterplotLayer,
  MapView,
  GeoJsonLayer,
  TextLayer
} from 'deck.gl'
import { DataFilterExtension } from '@deck.gl/extensions'
import { useDispatch, useSelector } from 'react-redux'
import { latLngToLocator, locatorToLatLng } from 'qth-locator'

import { renderTooltip, useInterval } from '../../utils'
import {
  selectNewestTimestamp,
  selectOldestTimestamp,
  selectHighestFrequency,
  selectLowestFrequency,
  reportCount
} from './spotSlice'
import { filterReceiver, filterSender } from '../settings/settingsSlice'
import { Sliders } from '../settings/Sliders'
import { bandColors } from '../../options'

const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoibWlscXVldG9hc3QiLCJhIjoiY2xjaDM0bG43OGV1MzN2cGxxYjlzajZkZiJ9.biDyx4pCkGlU_3PThTI-6A'

const INITIAL_VIEW_STATE = {
  longitude: 174.7455760648327,
  latitude: -36.8632553393452,
  zoom: 2
}

const dataFilter = new DataFilterExtension({
  filterSize: 3,
  fp64: true
  // countItems: true
})

export function SpotMap (props) {
  const { messageApi } = props
  const dispatch = useDispatch()
  const [deckViewState, setDeckViewState] = useState(INITIAL_VIEW_STATE)
  const [terminator, setTerminator] = useState(new GeoJSONTerminator())

  const [senderMaidenhead, setSenderMaidenhead] = useState(null)
  const [receiverMaidenhead, setReceiverMaidenhead] = useState(null)

  const settingsOpen = useSelector(state => state.settings.ui.settingsOpen)
  const viceVersa = useSelector(state => state.settings.viceVersa)

  const { timeRange, reportRange, frequencyRange, ui, markers } = useSelector(
    state => state.settings
  )
  const {
    showArcs,
    showReceiverMarkers,
    showTransmitterMarkers,
    markerSizeByReport,
    showBandColors,
  } = markers
  const { darkMode, droppingSenderLocator, droppingReceiverLocator } = ui

  const spots = useSelector(state => state.spots.spots)
  const oldestTimestamp = useSelector(selectOldestTimestamp)
  const newestTimestamp = useSelector(selectNewestTimestamp)
  const highestFrequency = useSelector(selectHighestFrequency)
  const lowestFrequency = useSelector(selectLowestFrequency)
  const filters = useSelector(state => state.settings.filters)

  const [picked, setPicked] = useState()

  /* update the terminator every 10 minutes */
  useInterval(() => {
    setTerminator(new GeoJSONTerminator())
  }, 1000 * 60 * 10)

  useEffect(() => {
    if (filters.sender.locator) {
      const locator = filters.sender.locator.substr(0, 4)
      const level = locator.length === 2 ? 1 : 2
      fetch(
        `https://raw.githubusercontent.com/neorxna/livespot/main/public/maidenhead_level${level}/grid/${locator}.json`
      )
        .then(response => response.json())
        .then(data => {
          setSenderMaidenhead(data)
        })
        .catch(e => {
          messageApi.error(
            'Problem connecting to the Maidenhead grid server :('
          )
        })
      if (level > 1)
        setDeckViewState(s => {
          const [latitude, longitude] = locatorToLatLng(filters.sender.locator)
          return { ...s, latitude, longitude, zoom: s.zoom < 5 ? 5 : s.zoom }
        })
    }
  }, [filters])

  useEffect(() => {
    if (filters.receiver.locator) {
      const locator = filters.receiver.locator.substr(0, 4)
      const level = locator.length === 2 ? 1 : 2
      fetch(
        `https://raw.githubusercontent.com/neorxna/livespot/main/public/maidenhead_level${level}/grid/${locator}.json`
      )
        .then(response => response.json())
        .then(data => {
          setReceiverMaidenhead(data)
        })
        .catch(e => {
          messageApi.error(
            'Problem connecting to the Maidenhead grid server :('
          )
        })
      if (level > 1)
        setDeckViewState(s => {
          const [latitude, longitude] = locatorToLatLng(
            filters.receiver.locator
          )
          return { ...s, latitude, longitude, zoom: s.zoom < 5 ? 5 : s.zoom }
        })
    }
  }, [filters])

  const filterProperties = {
    getFilterValue: spot => [spot.timestamp, spot.report, spot.frequency],
    filterRange: [
      [
        timeRange[0] === -Infinity ? oldestTimestamp : timeRange[0],
        timeRange[1] === Infinity ? newestTimestamp : timeRange[1]
      ],
      [
        reportRange[0] === -Infinity ? -1000 : reportRange[0],
        reportRange[1] === Infinity ? 1000 : reportRange[1]
      ],
      [ frequencyRange[0] === -Infinity ? lowestFrequency : frequencyRange[0]
        , frequencyRange[1] === Infinity ? highestFrequency : frequencyRange[1]]
    ],

    filterEnabled: true,
    /*onFilteredItemsChange: event => {
      const { id, count } = event
      if (
        [
          'transmitter-bubbles-layer',
          'receiver-bubbles-layer',
          'arc-layer'
        ].includes(id)
      ) {
        dispatch(reportCount({ layer: id, count }))
      }
    },*/
    extensions: [dataFilter]
  }

  const pickedSpot = spots.find(spot => spot.id === picked)

  const layers = [
    new GeoJsonLayer({
      id: 'terminator-layer',
      data: terminator,
      filled: true,
      getFillColor: [0, 0, 0, 30]
    }),
    new GeoJsonLayer({
      id: 'sender-maidenhead-layer',
      data: senderMaidenhead,
      filled: false,
      stroked: true,
      lineWidthScale: 2,
      lineWidthMinPixels: 2,
      getLineColor: () =>
        viceVersa
          ? darkMode
            ? [255, 255, 255, 255]
            : [0, 0, 0, 255]
          : [255, 0, 0, 255],
      getLineWidth: 2,
      visible: filters.sender.locator && senderMaidenhead,
      updateTriggers: {
        getLineColor: [viceVersa, darkMode]
      }
    }),
    new GeoJsonLayer({
      id: 'receiver-maidenhead-layer',
      data: receiverMaidenhead,
      filled: false,
      stroked: true,
      lineWidthScale: 2,
      lineWidthMinPixels: 2,
      getLineColor: () =>
        viceVersa
          ? darkMode
            ? [255, 255, 255, 255]
            : [0, 0, 0, 255]
          : [0, 255, 0, 255],
      getLineWidth: 2,
      visible: filters.receiver.locator && receiverMaidenhead,
      updateTriggers: {
        getLineColor: [viceVersa, darkMode]
      }
    }),
    new GreatCircleLayer({
      id: 'arc-layer',
      data: spots,
      wrapLongitude: true,
      pickable: true,
      getSourcePosition: d => d.from.coords,
      getTargetPosition: d => d.to.coords,
      getSourceColor: ({ hide, band }) => [
        ...(showBandColors ? bandColors[band] : [255, 0, 0]),
        hide ? 0 : picked ? 5 : 80
      ],
      getTargetColor: ({ hide, band }) => [
        ...(showBandColors ? bandColors[band] : [0, 255, 0]),
        hide ? 0 : picked ? 5 : 60
      ],
      widthMinPixels: 2,
      transitions: {
        getSourceColor: {
          duration: 500,
          enter: value => [value[0], value[1], value[2], 0]
        },
        getTargetColor: {
          duration: 1000,
          enter: value => [value[0], value[1], value[2], 0]
        }
      },
      updateTriggers: {
        getSourceColor: [picked, showBandColors],
        getTargetColor: [picked, showBandColors]
      },
      visible: showArcs,
      ...filterProperties
    }),
    pickedSpot &&
      new GreatCircleLayer({
        id: 'picked-arc-layer',
        data: [pickedSpot],
        wrapLongitude: true,
        pickable: true,
        getSourcePosition: d => d.from && d.from.coords,
        getTargetPosition: d => d.from && d.to.coords,
        getSourceColor: ({ hide, band }) => [
          ...(showBandColors ? bandColors[band] : [255, 0, 0]),
          hide ? 0 : 255
        ], // 80
        getTargetColor: ({ hide, band }) => [
          ...(showBandColors ? bandColors[band] : [0, 255, 0]),
          hide ? 0 : 255
        ], // 60
        widthMinPixels: 2,
        transitions: {
          getSourceColor: {
            duration: 500,
            enter: value => [value[0], value[1], value[2], 0]
          },
          getTargetColor: {
            duration: 1000,
            enter: value => [value[0], value[1], value[2], 0]
          }
        },
        visible: picked,
        ...filterProperties
      }),
    new ScatterplotLayer({
      id: 'transmitter-bubbles-layer',
      data: spots,
      pickable: true,
      opacity: 0.8,
      filled: true,
      radiusUnits: 'pixels',
      getPosition: d => d.from.coords,
      getRadius: ({ hide, report }) =>
        hide ? 0 : markerSizeByReport ? Math.max(5, report + 30) : 15,
      getFillColor: ({ band }) =>
        showBandColors ? [...bandColors[band], 40] : [255, 0, 0, 40],
      getLineColor: d => [0, 0, 0],
      transitions: {
        getRadius: {
          duration: 1000,
          enter: value => 0
        }
      },
      pickingRadius: 10,
      updateTriggers: {
        getRadius: markerSizeByReport,
        getFillColor: showBandColors
      },
      visible: showTransmitterMarkers,
      ...filterProperties
    }),
    new ScatterplotLayer({
      id: 'receiver-bubbles-layer',
      data: spots,
      pickable: true,
      opacity: 0.8,
      filled: true,
      radiusUnits: 'pixels',
      getPosition: d => d.to.coords,
      getRadius: ({ hide, report }) =>
        hide ? 0 : markerSizeByReport ? Math.max(5, report + 30) : 15,
      getFillColor: ({ band }) =>
        showBandColors ? [...bandColors[band], 40] : [0, 255, 0, 40],
      getLineColor: d => [0, 0, 0],
      pickingRadius: 10,
      transitions: {
        getRadius: {
          duration: 1000,
          enter: value => 0
        }
      },
      updateTriggers: {
        getRadius: markerSizeByReport,
        getFillColor: showBandColors
      },
      visible: showReceiverMarkers,
      ...filterProperties
    }),
    new TextLayer({
      id: 'sota-label-layer',
      data: spots.filter(spot => spot.sotaAlert),
      getText: () => 'Δ',
      getPosition: d => d.from.coords,
      getSize: 64,
      getAngle: 0,
      getTextAnchor: 'middle',
      getAlignmentBaseline: 'center',
      characterSet: ['Δ'],
      getColor: darkMode ? [255, 255, 255, 255] : [0, 0, 0, 255],
      pickable: true,
      updateTriggers: {
        getColor: darkMode
      }
    })
  ]

  return (
    <div
      className='map-container'
      style={{
        position: 'absolute',
        height: '100vh',
        width: `calc(100vw - ${settingsOpen ? '378px' : '0px'})`,
        position: 'relative',
        overflow: 'hidden',
        zIndex: 100,
        ...(droppingSenderLocator || droppingReceiverLocator
          ? { cursor: 'pointer' }
          : {})
      }}
    >
      <Sliders />
      {/*<div
        style={{ zIndex: '1000', position: 'absolute', bottom: '0', left: '0' }}
      >
        {JSON.stringify(picked)}
    </div>*/}
      <DeckGL
        initialViewState={deckViewState}
        controller={true}
        layers={layers}
        views={new MapView({ repeat: true })}
        getTooltip={({ object }) => renderTooltip(object, true)}
        onClick={event => {
          if (droppingSenderLocator || droppingReceiverLocator) {
            const { coordinate } = event
            const locator = latLngToLocator(coordinate[1], coordinate[0])
              .slice(0, 4)
              .toUpperCase()
            dispatch(
              droppingSenderLocator
                ? filterSender({ locator })
                : filterReceiver({ locator })
            )
          } else {
            const { object } = event
            if (object) {
              setPicked(id => (id === object.id ? null : object.id))
            } else {
              setPicked(null)
            }
          }
        }}
      >
        <Map
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
          mapStyle={`mapbox://styles/mapbox/${darkMode ? 'dark' : 'light'}-v11`}
        />
      </DeckGL>
    </div>
  )
}
