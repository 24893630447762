import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { renderTooltip } from '../../utils'
import { Card, Space, Statistic, Carousel } from 'antd'
import {
  selectFarthestDistance,
  selectFeaturedTxCountries,
  selectFeaturedRxCountries,
  selectHighestReport,
  selectLowestReport,
  selectPortableCallsigns,
  selectSotaActivators,
  selectSpotCount
} from '../map/spotSlice'
import { getCountryProperties } from '../../utils'

export function FeaturedSpots (props) {
  const { notificationApi } = props

  const spotCount = useSelector(selectSpotCount)
  const sotaActivators = useSelector(selectSotaActivators)

  const { top: topTx, bottom: bottomTx } = useSelector(
    selectFeaturedTxCountries
  )
  const { top: topRx, bottom: bottomRx } = useSelector(
    selectFeaturedRxCountries
  )

  const highestReport = useSelector(selectHighestReport)
  const lowestReport = useSelector(selectLowestReport)
  const portableCallsigns = useSelector(selectPortableCallsigns)
  const farthest = useSelector(selectFarthestDistance)

  useEffect(() => {
    if (sotaActivators.length) {
      const { from, sotaAlert } = sotaActivators[0]
      const sotaCallsign = from.callsign.replace('.','/')
      notificationApi.info({
        message: 'SOTA activator spotted',
        description: `${sotaCallsign} is possibly activating SOTA summit ${sotaAlert.associationCode}/${sotaAlert.summitCode}!`,
        placement: 'topLeft'
      })
    }
  }, [sotaActivators.length])

  useEffect(() => {
    if (portableCallsigns.length) {
      const { from, to } = portableCallsigns[0]
      const isTxPortable = from.callsign.endsWith('.P')
      const portableCallsign = isTxPortable ? from.callsign.replace('.','/') : to.callsign.replace('.','/')
      notificationApi.info({
        message: 'Portable callsign spotted',
        description: `${portableCallsign} is ${
          isTxPortable ? 'transmitting' : 'receiving'
        } portable!`,
        placement: 'topLeft'
      })
    }
  }, [portableCallsigns.length])

  const cardStyle = {
    width: '100%',
    height: '100%'
  }

  return (
    <Carousel style={{ flex: '1'}} autoplay={true}>
      <Card
        style={cardStyle}
        type='inner'
        size='small'
        title={'SOTA activators'}
      >
        {sotaActivators.length ? (
          <ul>
            {sotaActivators.map(spot => (
              <li key={spot.from.callsign}>
                {renderTooltip(spot, false, true)}
              </li>
            ))}
          </ul>
        ) : (
          <i>No SOTA activators</i>
        )}
      </Card>
      <Card
        style={cardStyle}
        type='inner'
        size='small'
        title={'Portable stations'}
      >
        {portableCallsigns.length ? (
          <ul>
            {portableCallsigns.map(spot => (
              <li key={spot.from.callsign}>
                {renderTooltip(spot, false, false, true)}
              </li>
            ))}
          </ul>
        ) : (
          <i>No portable stations</i>
        )}
      </Card>
      <Card
        style={cardStyle}
        type='inner'
        size='small'
        title={'Most common tx countries'}
      >
        <ul>
          {topTx.map(c => {
            const { emojiFlag, nameEn } = getCountryProperties(c)
            return (
              <li key={c}>
                <span
                  style={{
                    fontFamily: 'Noto Color Emoji'
                  }}
                >
                  {emojiFlag}
                </span>{' '}
                {nameEn}
              </li>
            )
          })}
        </ul>
      </Card>
      <Card
        style={cardStyle}
        type='inner'
        size='small'
        title={'Least common tx countries'}
      >
        <ul>
          {bottomTx.map(c => {
            const { emojiFlag, nameEn } = getCountryProperties(c)
            return (
              <li key={c}>
                <span
                  style={{
                    fontFamily: 'Noto Color Emoji'
                  }}
                >
                  {emojiFlag}
                </span>{' '}
                {nameEn}
              </li>
            )
          })}
        </ul>
      </Card>
      <Card
        style={cardStyle}
        type='inner'
        size='small'
        title={'Most common rx countries'}
      >
        <ul>
          {topRx.map(c => {
            const { emojiFlag, nameEn } = getCountryProperties(c)
            return (
              <li key={c}>
                <span
                  style={{
                    fontFamily: 'Noto Color Emoji'
                  }}
                >
                  {emojiFlag}
                </span>{' '}
                {nameEn}
              </li>
            )
          })}
        </ul>
      </Card>
      <Card
        style={cardStyle}
        type='inner'
        size='small'
        title={'Least common rx countries'}
      >
        <ul>
          {bottomRx.map(c => {
            const { emojiFlag, nameEn } = getCountryProperties(c)
            return (
              <li key={c}>
                <span
                  style={{
                    fontFamily: 'Noto Color Emoji'
                  }}
                >
                  {emojiFlag}
                </span>{' '}
                {nameEn}
              </li>
            )
          })}
        </ul>
      </Card>
      {highestReport && (
        <Card
          style={cardStyle}
          type='inner'
          size='small'
          title={'Highest report'}
        >
          {renderTooltip(highestReport)}
        </Card>
      )}
      {lowestReport && (
        <Card
          style={cardStyle}
          type='inner'
          size='small'
          title={'Lowest report'}
        >
          {renderTooltip(lowestReport)}
        </Card>
      )}
      {farthest && (
        <Card
          style={cardStyle}
          type='inner'
          size='small'
          title={'Farthest transmitted'}
        >
          <Space direction='vertical'>
            <Statistic suffix='km' precision={2} value={farthest.distance} />
            {renderTooltip(farthest)}
          </Space>
        </Card>
      )}
    </Carousel>
  )
}
